import React, {
  useContext, useState, useEffect
} from 'react';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next';

import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response';
import {
  ADD_TAG_TO_PRODUCT,
  ASSIGN_GHG_MODULE_TO_PRODUCT,
  ASSIGN_MODULE_TO_PRODUCT,
  CHANGE_PRODUCT_DESCRIPTION,
  CHANGE_PRODUCT_TYPE,
  CHANGE_PRODUCT_UNIT,
  CREATE_TAG,
  REMOVE_TAG_FROM_PRODUCT,
  RENAME_PRODUCT
} from '../../../graphql/mutation';
import DashboardContext from '../../../dashboard/context/dashboard.context';
import { IProduct, TTag } from '../../../model';
import { EpdModule, GhgModule } from '../../../../__generated__/graphql';
import { GHG_MODULES, MODULES, TAGS } from '../../../graphql/query';
import {
  getGhgModuleStorageItems,
  getModuleStorageItems,
  setGhgModuleStorageItem,
  setModuleStorageItem
} from '../../../shared/util/modules';
import { ItemDetailFormComponent } from '../../component/detail-panel/item-detail-form.component';
import { selectedWorkspaceVar } from '../../../../graphql/cache';
import { ItemDetailComponent } from '../../enum/impact-detail';
import { useScenarioAction } from '../../../analysis/hook/use-scenario-action';

import { AnalysisMode } from '../../../analysis/enum/analysis';
import { ScenarioAction } from '../../../analysis/enum/scenario-action';
import FeatureContext from '../../../dashboard/context/feature.context';
import { Feature } from '../../../shared/enum/feature';

enum TagAction {
  CreateTag, AddTagToProduct, RemoveTagFromProduct
}
export const ItemDetailContainer = ({
  selectedProduct
}: {
  selectedProduct: IProduct
}) => {
  const { t } = useTranslation([ 'product', 'common' ])
  const { space: { id: spaceID = '' } = {} } = useReactiveVar(selectedWorkspaceVar) || {}
  const { handleResponse } = useHandleMutationResponse(t)
  const {
    editableSidebar,
    updateDashboard,
    selectedAnalysisMode,
    afterChangeSidebarCallback = () => {},
    selectedInventoryItem,
    scenarioProductID,
    selectedEntity
  } = useContext(DashboardContext)
  const { isFeatureEnabled } = useContext(FeatureContext)
  const enableGHGReportingModule = isFeatureEnabled(Feature.GHGReportingModule) || false

  const storedModules = getModuleStorageItems() || []
  const storedGhgModules = getGhgModuleStorageItems() || []
  const [ modules, setModules ] = useState<EpdModule[]>(storedModules)
  const [ ghgModules, setGhgModules ] = useState<GhgModule[]>(storedGhgModules)

  const [ existingTags, setExistingTags ] = useState<TTag[]>([])
  const [ removedTagId, setRemovedTagId ] = useState<any>(null)
  const [ selectedTagAction, setSelectedTagAction ] = useState<TagAction | null>(null)

  const [ itemToEdit, setItemToEdit ] = useState<ItemDetailComponent | null>(null)
  const handleScenarioAction = useScenarioAction(scenarioProductID)

  const {
    loading: loadingModules,
    data: modulesResponse
  } = useQuery(MODULES, {
    skip: modules && modules.length > 0,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!loadingModules && modulesResponse?.epdModules) {
      setModules(modulesResponse?.epdModules)
      setModuleStorageItem(modulesResponse?.epdModules)
    }
  }, [ loadingModules, modulesResponse ]);

  const {
    loading: loadingGhgModules,
    data: ghgModulesResponse
  } = useQuery(GHG_MODULES, {
    skip: ghgModules && ghgModules.length > 0,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!loadingGhgModules && ghgModulesResponse?.ghgModules) {
      setGhgModules(ghgModulesResponse?.ghgModules)
      setGhgModuleStorageItem(ghgModulesResponse?.ghgModules)
    }
  }, [ loadingGhgModules, ghgModulesResponse ]);

  const {
    loading: loadingTags,
    data: tagsResponse,
  } = useQuery(TAGS, {
    skip: !spaceID,
    variables: {
      spaceID
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!loadingTags && tagsResponse?.tags) {
      setExistingTags(tagsResponse?.tags)
    }
  }, [ loadingTags, tagsResponse ]);

  const [
    renameProduct,
    {
      error: failedRenamingProduct,
      data: renameProductResponse = {},
      loading: renamingProduct
    }
  ] = useMutation(RENAME_PRODUCT)

  const productRenamed = renameProductResponse?.renameProduct
  useEffect(() => {
    if (!renamingProduct && (productRenamed || failedRenamingProduct)) {
      handleResponse({
        error: failedRenamingProduct,
        data: productRenamed,
        callback: () => afterChangeCallback(ItemDetailComponent.Name),
        successToastDetail: { label: 'messages.success', context: 'renameProduct' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'renameProduct' }
      })
    }
  }, [ renamingProduct, productRenamed, failedRenamingProduct ])

  const [
    changeProductUnit,
    {
      error: failedChangingProductUnit,
      data: changeProductUnitResponse = {},
      loading: changingProductUnit
    }
  ] = useMutation(CHANGE_PRODUCT_UNIT)

  const changedProductUnit = changeProductUnitResponse?.changeProductUnit
  useEffect(() => {
    if (!changingProductUnit && (changedProductUnit || failedChangingProductUnit)) {
      handleResponse({
        error: failedChangingProductUnit,
        data: changedProductUnit,
        callback: () => afterChangeCallback(ItemDetailComponent.Unit),
        successToastDetail: { label: 'messages.success', context: 'changeProductUnit' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'changeProductUnit' }
      })
    }
  }, [ changingProductUnit, changedProductUnit, failedChangingProductUnit ])

  const [
    changeProductDescription,
    {
      error: failedChangingProductDescription,
      data: changeProductDescriptionResponse = {},
      loading: changingProductDescription
    }
  ] = useMutation(CHANGE_PRODUCT_DESCRIPTION)

  const changedProductDescription = changeProductDescriptionResponse?.changeProductDescription
  useEffect(() => {
    if (!changingProductDescription && (changedProductDescription || failedChangingProductDescription)) {
      handleResponse({
        error: failedChangingProductDescription,
        data: changedProductDescription,
        callback: () => afterChangeCallback(ItemDetailComponent.Description),
        successToastDetail: { label: 'messages.success', context: 'changeProductDescription' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'changeProductDescription' }
      })
    }
  }, [ changingProductDescription, changedProductDescription, failedChangingProductDescription ])

  const [
    changeProductType,
    {
      error: failedChangingProductType,
      data: changeProductTypeResponse = {},
      loading: changingProductType
    }
  ] = useMutation(CHANGE_PRODUCT_TYPE)

  const changedProductType = changeProductTypeResponse?.changeProductType
  useEffect(() => {
    if (!changingProductType && (changedProductType || failedChangingProductType)) {
      handleResponse({
        error: failedChangingProductType,
        data: changedProductType,
        failedCallback: () => setItemToEdit(null),
        callback: () => afterChangeCallback(ItemDetailComponent.Type),
        successToastDetail: { label: 'messages.success', context: 'changeProductType' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'changeProductType' }
      })
    }
  }, [ changingProductType, changedProductType, failedChangingProductType ])

  const [
    assignEpdModuleToProduct,
    {
      error: failedAssignModuleToProduct,
      data: assignedModuleToProductResponse = {},
      loading: assigningModuleToProduct
    }
  ] = useMutation(ASSIGN_MODULE_TO_PRODUCT)

  const assignedModuleToProduct = assignedModuleToProductResponse?.assignEpdModuleToProduct
  useEffect(() => {
    if (!assigningModuleToProduct && (assignedModuleToProduct || failedAssignModuleToProduct)) {
      handleResponse({
        error: failedAssignModuleToProduct,
        data: assignedModuleToProduct,
        callback: () => afterChangeCallback(ItemDetailComponent.Module),
        successToastDetail: { label: 'messages.success', context: 'assignModuleToProduct' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'assignModuleToProduct' }
      })
    }
  }, [ assigningModuleToProduct, assignedModuleToProduct, failedAssignModuleToProduct ])

  const [
    assignGhgModuleToProduct,
    {
      error: failedAssignGhgModuleToProduct,
      data: assignedGhgModuleToProductResponse,
      loading: assigningGhgModuleToProduct
    }
  ] = useMutation(ASSIGN_GHG_MODULE_TO_PRODUCT)

  const assignedGhgModuleToProduct = assignedGhgModuleToProductResponse?.assignGhgModuleToProduct
  useEffect(() => {
    if (!assigningGhgModuleToProduct && (assignedGhgModuleToProduct || failedAssignGhgModuleToProduct)) {
      handleResponse({
        error: failedAssignGhgModuleToProduct,
        data: assignedGhgModuleToProduct,
        callback: () => afterChangeCallback(ItemDetailComponent.GHGModule),
        successToastDetail: { label: 'messages.success', context: 'assignGhgModuleToProduct' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'assignGhgModuleToProduct' }
      })
    }
  }, [ assigningGhgModuleToProduct, assignedGhgModuleToProduct, failedAssignGhgModuleToProduct ])

  const [
    createTag,
    {
      error: failedCreatingTag,
      data: createdTagResponse = {},
      loading: creatingTag
    }
  ] = useMutation(CREATE_TAG)

  const [
    addTagToProduct,
    {
      error: failedAddingTagToProduct,
      data: addedTagResponse = {},
      loading: addingTag
    }
  ] = useMutation(ADD_TAG_TO_PRODUCT)

  const [
    removeTagFromProduct,
    {
      error: failedRemoveTagFromProduct,
      data: removedTagResponse = {},
      loading: removingTagFromProduct
    }
  ] = useMutation(REMOVE_TAG_FROM_PRODUCT)

  const createdTag = createdTagResponse?.createTag
  useEffect(() => {
    if (!creatingTag && (createdTag || failedCreatingTag)) {
      handleResponse({
        error: failedCreatingTag,
        data: createdTag,
        callback: () => afterChangeCallback(ItemDetailComponent.Tags),
        successToastDetail: { label: 'messages.success', context: 'createTag' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'createTag' }
      })
    }
  }, [ creatingTag, createdTag, failedCreatingTag ])

  const removedTag = removedTagResponse?.removeTagFromProduct
  useEffect(() => {
    if (!removingTagFromProduct && (removedTag || failedRemoveTagFromProduct)) {
      handleResponse({
        error: failedRemoveTagFromProduct,
        data: removedTag,
        callback: () => afterChangeCallback(ItemDetailComponent.Tags),
        successToastDetail: { label: 'messages.success', context: 'removeTagFromProduct' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'removeTagFromProduct' }
      })
    }
  }, [ removingTagFromProduct, removedTag, failedRemoveTagFromProduct ])

  const addedTags = addedTagResponse?.addTagToProduct
  useEffect(() => {
    if (!addingTag && (addedTags || failedAddingTagToProduct)) {
      handleResponse({
        error: failedAddingTagToProduct,
        data: addedTags,
        callback: () => afterChangeCallback(ItemDetailComponent.Tags),
        successToastDetail: { label: 'messages.success', context: 'addTagToProduct' },
        errorToastSummary: { label: 'messages.errorSummary', context: 'addTagToProduct' }
      })
    }
  }, [ addingTag, addedTags, failedAddingTagToProduct ])

  const getUpdatedProductTags = () => {
    const existingTags: TTag[] = selectedProduct?.tags || []
    switch (selectedTagAction) {
    case TagAction.CreateTag:
      return { tags: [ ...existingTags, createdTag ] }
    case TagAction.AddTagToProduct: {
      const { tags = [] } = addedTags || {}
      return { tags }
    }
    case TagAction.RemoveTagFromProduct: {
      const tagsAfterRemove = [ ...existingTags ]
      const removedTagIndex = tagsAfterRemove.findIndex(tag => tag.id === removedTagId)
      if (removedTagIndex < 0) return

      tagsAfterRemove.splice(removedTagIndex, 1)
      return { tags: [ ...tagsAfterRemove ] }
    }
    }
  }

  const afterChangeCallback = (item: ItemDetailComponent) => {
    if (!selectedProduct?.id) return;

    const productUpdates: Partial<Record<ItemDetailComponent, any>> = {
      [ItemDetailComponent.Name]: productRenamed,
      [ItemDetailComponent.Unit]: changedProductUnit,
      [ItemDetailComponent.Description]: changedProductDescription,
      [ItemDetailComponent.Type]: changedProductType,
      [ItemDetailComponent.Module]: assignedModuleToProduct,
      [ItemDetailComponent.GHGModule]: assignedGhgModuleToProduct,
      [ItemDetailComponent.Tags]: getUpdatedProductTags(),
    };

    const updatedProductData = productUpdates[item];

    if (updatedProductData) {
      setItemToEdit(null);
      afterChangeSidebarCallback();
      updateDashboard({ selectedEntity: { ...selectedProduct, ...updatedProductData } });
    }
  };

  const afterScenarioChangeCallback = (error: any, data: any) => {
    const context = itemToEdit === ItemDetailComponent.Name ? 'renameProduct' : 'changeProductUnit'
    handleResponse({
      error,
      data,
      callback: () => {
        const { performScenarioAction: product } = data || {}
        setItemToEdit(null)
        afterChangeSidebarCallback()
        product && updateDashboard({ selectedEntity: product })
      },
      successToastDetail: { label: 'messages.success', context },
      errorToastSummary: { label: 'messages.errorSummary', context }
    })
  }

  const handleTags = (data: any, productID: string) => {
    const {
      name, color, tag, removeTagId
    } = data || {}
    if (!spaceID) return

    if (tag) {
      setSelectedTagAction(TagAction.AddTagToProduct)
      addTagToProduct({
        variables: {
          tagID: tag.id,
          productID
        }
      })
    } else if (name && color) {
      setSelectedTagAction(TagAction.CreateTag)
      createTag({
        variables: {
          name, color, spaceID, productID
        }
      })
    } else if (removeTagId) {
      setSelectedTagAction(TagAction.RemoveTagFromProduct)
      setRemovedTagId(removeTagId)
      removeTagFromProduct({
        variables: {
          tagID: removeTagId, productID
        }
      })
    }
  }

  const handleInventoryAction = (data: any, item: ItemDetailComponent) => {
    const productID = selectedProduct?.id
    if (!productID) return

    switch (item) {
    case ItemDetailComponent.Name:
      return renameProduct({ variables: { name: data.name, productID } })
    case ItemDetailComponent.Unit:
      return changeProductUnit({ variables: { unit: data.unit, productID } })
    case ItemDetailComponent.Description:
      return changeProductDescription({ variables: { description: data.description, productID } })
    case ItemDetailComponent.Type:
      return changeProductType({ variables: { type: data.type, productID } })
    case ItemDetailComponent.Module:
      return assignEpdModuleToProduct({ variables: { moduleID: data.module, productID } })
    case ItemDetailComponent.GHGModule:
      return assignGhgModuleToProduct({ variables: { moduleID: data.ghgModule, productID } })
    case ItemDetailComponent.Tags: {
      return handleTags(data, productID)
    }
    }
  }

  const getScenarioAction = (item: ItemDetailComponent): ScenarioAction | null => {
    if (item !== ItemDetailComponent.Name && item !== ItemDetailComponent.Unit) return null
    const scenarioAction = {
      [ItemDetailComponent.Name]: ScenarioAction.RenameProduct,
      [ItemDetailComponent.Unit]: ScenarioAction.ChangeProductUnit
    }

    return scenarioAction[item]
  }

  const handleFormSubmit = (data: any, item: ItemDetailComponent) => {
    const scenarioAction = getScenarioAction(item)
    if (selectedAnalysisMode === AnalysisMode.Scenario && scenarioAction) {
      const variables = { productID: selectedEntity?.id, ...data }
      handleScenarioAction && selectedInventoryItem?.key
        && selectedEntity?.id
        && handleScenarioAction(scenarioAction, variables, selectedInventoryItem.key, afterScenarioChangeCallback)
    } else {
      handleInventoryAction(data, item);
    }
  }

  const formItemProps = {
    [ItemDetailComponent.Unit]: {
      value: selectedProduct?.unit, label: t('labels.unit', { ns: 'common' })
    },
    [ItemDetailComponent.Name]: {
      value: selectedProduct?.name, label: t('labels.name', { ns: 'common' })
    },
    [ItemDetailComponent.Description]: {
      value: selectedProduct?.description, label: t('labels.description', { ns: 'common' })
    },
    [ItemDetailComponent.Type]: {
      value: selectedProduct?.type, label: t('labels.type', { ns: 'common' })
    },
    [ItemDetailComponent.Module]: {
      value: selectedProduct?.epdModule, label: t('labels.module', { context: 'epd', ns: 'common' }), modules
    },
    [ItemDetailComponent.GHGModule]: {
      value: selectedProduct?.ghgModule, label: t('labels.module', { context: 'ghg', ns: 'common' }), ghgModules
    },
    [ItemDetailComponent.Tags]: {
      value: selectedProduct?.tags, label: t('labels.tags', { ns: 'common' }), existingTags
    },
    [ItemDetailComponent.MainProperty]: {
      value: selectedProduct, label: t('labels.mainProperty', { ns: 'common' })
    },
    [ItemDetailComponent.AdditionalProperties]: {
      value: selectedProduct, label: t('labels.additionalProperties', { ns: 'common' })
    }
  }

  const shouldIncludeItem = (item: ItemDetailComponent) => enableGHGReportingModule || item !== ItemDetailComponent.GHGModule

  return (
    <>
      { selectedProduct && (
        <div className="flex flex-column w-full">
          { Object.values(ItemDetailComponent)
            .filter(shouldIncludeItem)
            .map((item, index) => (
              <div key={`item-detail-form-item-${index}`} className="flex flex-column w-full py-2">
                <ItemDetailFormComponent editableSidebar={editableSidebar} item={item} handleFormSubmit={handleFormSubmit} {...formItemProps[item]} {...{ itemToEdit, setItemToEdit }} />
              </div>
            ))}
        </div>
      )}
    </>
  )
}
