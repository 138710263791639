import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'

import { PERFORM_SCENARIO_ACTION } from '../../graphql/mutation'
import { ScenarioAction } from '../enum/scenario-action'

export const useScenarioAction = (scenarioProductID?: string | null) => {
  const [ afterScenario, setAfterScenario ] = useState<{ callback?: Function }>({})

  const [
    performScenarioAction,
    {
      error: failedPerformingScenarioAction,
      data: performScenarioActionData,
      loading: performingScenarioAction
    }
  ] = useMutation(PERFORM_SCENARIO_ACTION)

  useEffect(() => {
    if (!performingScenarioAction && afterScenario?.callback
      && (failedPerformingScenarioAction || performScenarioActionData)) {
      performScenarioActionCallback()
    }
  }, [ failedPerformingScenarioAction, performScenarioActionData, performingScenarioAction ])

  if (!scenarioProductID) return

  const performScenarioActionCallback = () => {
    afterScenario?.callback && afterScenario.callback(failedPerformingScenarioAction, performScenarioActionData)
  }

  const handleScenarioAction = (
    action: ScenarioAction,
    argument: any,
    inventoryNodeID: string,
    callback: Function
  ) => {
    if (scenarioProductID) {
      setAfterScenario({ callback })
      performScenarioAction({
        variables: {
          action,
          args: JSON.stringify(argument),
          inventoryNodeID,
          productID: argument.productID,
          scenarioProductID
        }
      })
    }
  }

  return handleScenarioAction
}
