import React, { useState, useRef, useEffect } from 'react';

import styled from 'styled-components';

import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button'

import { IProductProperty, IReferenceProperty, TReferenceUnit } from '../../../../model';
import { IControllerRender } from '../../../../shared/interface/react-form-hook';

const FormItemEditMode = styled.div`
  form {
    width: 100%;
  }

  .p-dropdown,
  input {
    width: 100%;
    height: 2rem;
  }

  .p-inputtext {
    padding: 0.25rem 0.5rem;
  }

  .item-amount-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .disabled-dropdown.p-dropdown {
    background: var(--gray-50);

    .p-inputtext,
    .p-dropdown-trigger-icon {
      color: var(--gray-200);
    }
  }
`

type TAdditionalPropertyFormComponentProps = {
  isMain?: boolean,
  referenceProperties?: IReferenceProperty[] | null
  productProperty?: IProductProperty | null
  propertiesOptions?: any[]
  setEnablePropertyEdit: (param: boolean) => void,
  handleAddProductProperty: (param: IProductProperty) => void
}
export const AdditionalPropertyFormComponent = ({
  isMain = false,
  productProperty = {},
  propertiesOptions = [],
  referenceProperties = [],
  setEnablePropertyEdit,
  handleAddProductProperty
}: TAdditionalPropertyFormComponentProps) => {
  const { t } = useTranslation([ 'common' ]);
  const defaultValues = {
    referenceUnit: productProperty?.referenceUnit?.id,
    referenceProperty: productProperty?.referenceProperty?.id,
    conversionFactor: productProperty?.conversionFactor
  };
  const {
    control, handleSubmit, formState: { errors = {} }, setValue
  } = useForm({ defaultValues });
  const formRef = useRef<HTMLFormElement>(null);
  const [ unitOptions, setUnitOptions ] = useState<any[]>([]);
  useEffect(() => {
    if (defaultValues.referenceProperty) {
      setSelectedUnits(defaultValues.referenceProperty);
    }
  }, [ productProperty ])

  const getErrorMessage = (context: string): string => t('messages.errorMessage', { context });

  const onSubmit = (data: any) => {
    handleAddProductProperty(data);
  }

  const setSelectedUnits = (selectedReferenceId: string) => {
    const selectedProductProperty = referenceProperties?.find(referenceProperty => referenceProperty.id === selectedReferenceId);
    if (!selectedProductProperty?.id) return;

    const { referenceUnits = [] } = selectedProductProperty || {};
    const unitOptions = referenceUnits.map((referenceUnit: TReferenceUnit) => ({ label: referenceUnit.name, value: referenceUnit.id, conversionUnitId: referenceUnit.conversionUnitID }))
    unitOptions.forEach(opt => {
      if (!defaultValues.referenceUnit && opt.value === opt.conversionUnitId) {
        setValue('referenceUnit', opt.value)
      }
    })
    setUnitOptions(unitOptions);
  }

  const triggerFormSubmit = () => {
    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }

  return (
    <FormItemEditMode className="flex flex-column w-full">
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>

        <div className="flex flex-column w-full py-1">
          <div className="flex align-items-center text-xs text-gray-300 gap-1 px-2">
            { t('labels.property') }
          </div>
          <Controller
            name="referenceProperty"
            control={control}
            rules={{ required: getErrorMessage('property') }}
            render={({ field, fieldState }: IControllerRender) => (
              <Dropdown
                {...field}
                options={propertiesOptions}
                onChange={(e: any) => {
                  field.onChange(e);
                  setSelectedUnits(e.value);
                }}
                onKeyUp={(e: any) => {
                  if (e.key === 'Escape') setEnablePropertyEdit(false)
                  else if (e.key === 'Enter') triggerFormSubmit()
                }}
                id="referenceProperty"
                panelClassName="module-dropdown-panel"
                placeholder={t('labels.select', { context: 'property' })}
                className={classNames('w-full', { 'p-invalid': fieldState.error, 'disabled-dropdown': isMain })}
                disabled={isMain}
              />
            )}
          />
          { errors?.referenceProperty && <small className="p-error">{ errors?.referenceProperty?.message }</small> }
        </div>

        { unitOptions.length > 0 && (
          <>
            <div className="flex flex-column w-full py-1">
              <div className="flex align-items-center text-xs text-gray-300 gap-1 px-2">
                { t('labels.unit') }
              </div>
              <Controller
                name="referenceUnit"
                control={control}
                rules={{ required: getErrorMessage('unit') }}
                render={({ field, fieldState }: IControllerRender) => (
                  <Dropdown
                    {...field}
                    options={unitOptions}
                    id="referenceUnit"
                    panelClassName="module-dropdown-panel"
                    placeholder={t('labels.select', { context: 'unit' })}
                    className={classNames('w-full', { 'p-invalid': fieldState.error })}
                  />
                )}
              />
              { errors?.referenceUnit && <small className="p-error">{ errors?.referenceUnit?.message }</small> }
            </div>
            <div className="flex flex-column w-full py-1">
              <div className="flex align-items-center text-xs text-gray-300 gap-1 px-2">
                { t('labels.amount') }
              </div>
              <Controller
                name="conversionFactor"
                control={control}
                rules={{ required: getErrorMessage('amount') }}
                render={({ field, fieldState }: IControllerRender) => (
                  <InputText
                    id="conversionFactor"
                    lang="en-IN"
                    type="number"
                    {...field}
                    autoFocus
                    autoComplete="off"
                    data-testid="property-amount"
                    placeholder={t('labels.add', { context: 'amount' })}
                    className={classNames('w-full item-amount-input', { 'p-invalid': fieldState.error })}
                  />
                )}
              />
              { errors?.conversionFactor && <small className="p-error">{ errors?.conversionFactor?.message }</small> }
            </div>
          </>
        )}

        <div className="col-12 px-0 flex justify-content-end gap-2">
          <Button
            type="button"
            iconPos="left"
            label={t('actions.cancel')}
            onClick={() => setEnablePropertyEdit(false)}
            data-testid="update-product-property-cancel"
            className="p-button-outlined p-button-plain justify-content-end h-2rem"
          />

          <Button
            type="button"
            iconPos="left"
            label={t('actions.save')}
            onClick={triggerFormSubmit}
            className="p-button-primary h-2rem"
            data-testid="update-product-property-submit"
          />
        </div>

      </form>
    </FormItemEditMode>
  )
}
