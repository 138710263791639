import React, { useContext } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons'
import { FiMousePointer } from 'react-icons/fi'
import styled from 'styled-components'
import { SimpleCardTemplateComponent } from '../../shared/component/general/simple-card-template.component'
import { ExternalSources } from '../../shared/enum'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { selectedAccountVar } from '../../../graphql/cache'

declare global {
  interface Window {
    Appcues:any;
  }
}

const ItemWrapper = styled.div`
  height: 2.625rem;
`

export const WorkspaceHelpStartComponent = () => {
  const { t } = useTranslation([ 'workspace' ])
  const [ trackEventInSegment ] = getSegmentTrack()
  const { openTalkToExpert = () => {} } = useContext<IDashboardContext>(DashboardContext)

  const {
    account
  } = useReactiveVar(selectedAccountVar) || {}
  const {
    hasValidSubscription = false
  } = account || {}

  const header = (
    <div className="text-base font-semibold">
      { t('labels.helpCenter', { context: 'gettingStartedHeader' }) }
    </div>
  )

  const takeATour = () => {
    trackEventInSegment(TrackEventType.VIEWED_WORKSPACE_TOUR)
    window.Appcues.show('36859032-ce2a-4d7c-80f7-b4b016ce8edd')
  }

  const learnByExercise = () => {
    trackEventInSegment(TrackEventType.VIEWED_WORKSPACE_EXERCISE)
    window.open(ExternalSources.HelpCenterExercise)
  }

  const learnToWorkWithMobius = () => {
    trackEventInSegment(TrackEventType.VIEWED_WORKSPACE_EXERCISE)
    const learningPath = ExternalSources.HelpCenterMobiusFreeCourse
    window.open(learningPath)
  }

  const chatToOurTeam = () => {
    trackEventInSegment(hasValidSubscription ? TrackEventType.VIEWED_WORKSPACE_APPOINTMENT_PAID : TrackEventType.VIEWED_WORKSPACE_APPOINTMENT_TRIAL)
    openTalkToExpert()
  }

  const helpCenterExerciseItem = hasValidSubscription ? {
    icon: <FiMousePointer size={22} style={{ margin: '0.45rem 0 0 0.313rem' }} />,
    iconClassName: 'border-round-xl bg-primary-50 text-gray-700 py-2 px-2',
    label: t('labels.helpCenter', { context: 'learnToWorkWithMobius' }),
    buttonLabel: t('actions.takeAFreeCourse'),
    duration: '3 min',
    onclick: learnToWorkWithMobius
  } : {
    icon: <FiMousePointer size={22} style={{ margin: '0.45rem 0 0 0.313rem' }} />,
    iconClassName: 'border-round-xl bg-primary-50 text-gray-700 py-2 px-2',
    label: t('labels.helpCenter', { context: 'learnByExercise' }),
    buttonLabel: t('actions.create', { context: 'ApplePie' }),
    duration: '3 min',
    onclick: learnByExercise
  }

  const helpCenterContentList = [
    {
      icon: <FontAwesomeIcon icon={faCircleQuestion} size="xl" className="mt-1 ml-1" />,
      iconClassName: 'border-round-xl bg-primary-50 text-gray-700 py-2 px-2',
      buttonLabel: t('actions.takeATour'),
      label: t('labels.helpCenter', { context: 'showMobius' }),
      duration: '5 min',
      onclick: takeATour
    },
    helpCenterExerciseItem,
    {
      icon: <i className="pi pi-users text-2xl" style={{ margin: '0.313rem 0 0 0.313rem' }} />,
      iconClassName: 'border-round-xl bg-primary-50 text-gray-700 py-2 px-2',
      buttonLabel: t('actions.book', { context: hasValidSubscription ? 'freeAppointment' : 'freeOnboardingCall' }),
      label: t('labels.helpCenter', { context: 'chatToOurTeam' }),
      duration: '3 min',
      onclick: chatToOurTeam
    }
  ]

  return (
    <SimpleCardTemplateComponent
      header={header}
      boxShadow="0px 6px 30px 6px rgba(0, 0, 0, 0.05)"
      className="bg-white border-round-lg text-base font-medium text-gray-700 p-4"
      headerClassName="border-gray-200 border-bottom-1"
    >
      <div data-testid="workspace-help-start-wrapper" className="flex flex-column w-full h-full pt-2">
        {
          helpCenterContentList.map((item, index) => (
            <ItemWrapper onClick={item.onclick} key={`help-start-${index}`} className="flex w-full my-2 px-0">
              <div className={`flex-none w-3rem h-3rem ${item.iconClassName}`}>
                {item.icon}
              </div>
              <div className="flex flex-column flex-grow-1 pl-3">
                <div className="w-full text-sm font-normal text-gray-700 pt-1">{item.label}</div>
                <div className="w-full font-normal text-gray-300">
                  <Button
                    icon={<FontAwesomeIcon icon={faArrowRight} className="mr-2 py-1" size="lg" />}
                    label={item.buttonLabel}
                    className="p-button-link text-primary-500 p-0 cursor-pointer line-height-2 text-sm"
                  />
                </div>
              </div>
            </ItemWrapper>
          ))
        }
      </div>
    </SimpleCardTemplateComponent>
  )
}
