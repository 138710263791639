import React, { useContext, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { REMOVE_CONVERSION_ITEM } from '../../../graphql/mutation'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { DeleteTreeItemComponent } from '../../component/dialog/delete-tree-item.component'
import { AnalysisMode } from '../../../analysis/enum/analysis'
import { ScenarioAction } from '../../../analysis/enum/scenario-action'
import { useScenarioAction } from '../../../analysis/hook/use-scenario-action'
import { TAnalysisContext } from '../../../analysis/interface/analysis.context'
import AnalysisContext from '../../../analysis/provider/context/analysis.context'
import { useHandleMutationResponse } from '../../../shared/hook/use-handle-mutation-response'

type TDeleteProductContainerProps = {
  afterDeleteTreeItem: () => void
}

export const DeleteTreeItemContainer = ({
  afterDeleteTreeItem
}: TDeleteProductContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])
  const { handleResponse } = useHandleMutationResponse(t)
  const {
    selectedNode,
    analysisMode,
    updateProductDetail,
    showDeleteTreeItem = false
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { scenarioProductID } = useContext<TAnalysisContext>(AnalysisContext)
  const handleScenarioAction = useScenarioAction(scenarioProductID)

  const { data: { inventoryItem = null } = {}, key } = selectedNode || {}
  const { product, conversionID } = inventoryItem || {}
  const [
    removeConversionItem,
    {
      error: failedRemovingConversionItem,
      data: removeConversionItemData,
      loading: removingConversionItem
    }
  ] = useMutation(REMOVE_CONVERSION_ITEM)
  const removedConversionItem = removeConversionItemData?.removeConversionItem

  useEffect(() => {
    if (!removingConversionItem && (removedConversionItem || failedRemovingConversionItem)) {
      handleRemoveSubItemResponse(failedRemovingConversionItem, removedConversionItem)
    }
  }, [ removingConversionItem, removedConversionItem, failedRemovingConversionItem ])

  const handleRemoveSubItemResponse = (error: any, data: any) => {
    handleResponse({
      error,
      data,
      callback: removeTreeItemCallback,
      successToastDetail: { label: 'messages.success', context: 'removeInventoryItem', ns: 'product' },
      errorToastSummary: { label: 'messages.errorSummary', context: 'removeInventoryItem', ns: 'product' }
    })
  }

  const closeDeleteTreeItemDialog = () => {
    updateProductDetail({ selectedNode: null, showDeleteTreeItem: false })
  }

  const handleDeleteTreeItem = () => {
    if (!conversionID || !product?.id) return

    const variables = { conversionID, productID: product.id }
    if (analysisMode === AnalysisMode.Scenario && key) {
      handleScenarioAction && handleScenarioAction(ScenarioAction.RemoveConversionItem, variables, key, handleRemoveSubItemResponse)
    } else {
      removeConversionItem({ variables })
    }
  }

  const removeTreeItemCallback = () => {
    afterDeleteTreeItem()
    closeDeleteTreeItemDialog()
  }

  return (
    <>
      <DeleteTreeItemComponent
        removingConversionItem={removingConversionItem}
        handleDeleteTreeItem={handleDeleteTreeItem}
        closeDeleteTreeItemDialog={closeDeleteTreeItemDialog}
        showDeleteTreeItem={showDeleteTreeItem}
      />
    </>
  )
}
