import { Environment } from '../enum'
import { LocalStorage } from '../enum/storage'

export const downloadByUrl = (filePath: string) => {
  const { port, hostname, protocol } = window.location
  window.location.assign(`${protocol}//${hostname}:${port}${filePath}`)
}

export const getEnvFromUrl = (): Environment | null => {
  const { hostname } = window.location

  if (!hostname) return null

  switch (hostname) {
  case 'localhost':
    return Environment.dev
  case 'mobius.ecochain-tst.com':
  case 'internal-mobius.ecochain-tst.com':
    return Environment.tst
  case 'mobius.ecochain-acc.com':
  case 'internal-mobius.ecochain-acc.com':
    return Environment.acc
  case 'mobius-prd.ecochain.com':
  case 'mobius.ecochain.com':
    return Environment.prd
  default:
    return Environment.unknown
  }
}

type NavigationValue = {
  pathname: string
  data: any
}

export const getNavigationStorageItem = (key: string) => {
  const navigation = localStorage.getItem(LocalStorage.Navigation)
  if (navigation) {
    return JSON.parse(navigation)[key]
  }
}

export const setNavigationStorageItem = (key: string, value: NavigationValue) => {
  const navigation = localStorage.getItem(LocalStorage.Navigation)
  const navigationObj = navigation ? JSON.parse(navigation) : {}
  localStorage.setItem(LocalStorage.Navigation, JSON.stringify({
    ...navigationObj,
    [key]: value
  }))
}

export const removeNavigationStorageItem = (key: string) => {
  const navigation = localStorage.getItem(LocalStorage.Navigation)
  if (navigation) {
    const navigationObj = JSON.parse(navigation)
    delete navigationObj[key]
    localStorage.setItem(LocalStorage.Navigation, JSON.stringify(navigationObj))
  }
}

export const updateNavigationStorageItemData = (key: string, newData: any = {}) => {
  const navigationItem = getNavigationStorageItem(key) || {}
  const { data = {} } = navigationItem
  setNavigationStorageItem(key, { ...navigationItem, data: { ...data, ...newData } })
}
