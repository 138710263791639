import React, {
  useContext, useState, useRef, useEffect
} from 'react';
import styled from 'styled-components'
import { classNames } from 'primereact/utils'
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client'

import { Tooltip } from 'primereact/tooltip'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faDatabase,
  faCircleNodes,
  faCircleInfo,
  faPen,
  faTrash,
  faPenField,
  faExclamationTriangle
} from '@fortawesome/pro-regular-svg-icons';

import { IDashboardContext } from '../../../../shared/interface/workspace-context-type';
import DashboardContext from '../../../../dashboard/context/dashboard.context';
import { IProduct } from '../../../../model';
import { ReferenceProductType } from '../../../../impact-dataset/enum/reference-product';
import { useDatasetNavigate } from '../../../../impact-dataset/hook/use-dataset-navigate';
import { DecimalPointComponent } from '../../../../shared/component/general/decimal-point.component';
import { DecimalViewType } from '../../../../shared/enum';
import { selectedWorkspaceVar } from '../../../../../graphql/cache';
import { TooltipOverflowContent } from '../../../../shared/hook/use-ref-overflow';
import { ImpactDatasetViewType } from '../../../../impact-dataset/enum/impact-dataset-view-type';
import { Status } from '../../../../shared/enum/status';
import { getDecimalDisplayValue } from '../../../../shared/util/decimal';

const PreviewHeader = styled.div`
  min-height: 1.25rem;
  max-height: 3.375rem;
  overflow: hidden;
  line-height: 1.125rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: normal;
  -webkit-line-clamp: 'none';
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`

const ImpactPreviewWrapper = styled.div`

  .p-inline-message {
    width: 100%;
    padding: 1rem 1.25rem;
    padding-right: 0;
    border-width: 1px 1px 1px 4px;
  }

  .impact-preview-dataset-label {
    min-height: 1.25rem;
    max-height: 2.5rem;
    overflow: hidden;
    line-height: 1.25rem !important;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    word-break: normal;
    -webkit-line-clamp: 'none';
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`

export const ImpactPreviewComponent = () => {
  const { t } = useTranslation([ 'impact-dataset', 'common' ]);
  const [ isOverflowing, setIsOverflowing ] = useState(false)
  const [ enableChangeDataset, setEnableChangeDataset ] = useState(false)
  const [ isCalculationShown, setIsCalculationShown ] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)
  const impactRef = useRef<HTMLDivElement>(null)
  const datasetRef = useRef<HTMLDivElement>(null)
  const {
    selectedEntity,
    selectedEntityImpact,
    selectedInventoryItem,
    scenarioProductID,
    updateDashboard,
    editableSidebar,
    closeSidebar = () => {}
  } = useContext<IDashboardContext>(DashboardContext);
  const selectedProduct = selectedEntity as IProduct
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { datasetNavigate, navigateToDatasetDetail } = useDatasetNavigate(selectedProduct, scenarioProductID, selectedInventoryItem?.key)
  const { inventoryItem = {} } = selectedInventoryItem?.data || {}
  const {
    referenceProduct,
    hasInventory,
    customImpacts,
    unit,
    name
  } = selectedProduct || {}
  const {
    impactCategory, impactMethod
  } = space || {}

  const productHasCustomImpact = customImpacts?.some(impact => Math.abs(Number(impact.amount)) > 0)
  const productHasNoImpact = !productHasCustomImpact && !referenceProduct
  const isCustomImpact = !referenceProduct && productHasCustomImpact
  const isProductSystem = referenceProduct?.type === ReferenceProductType.ProductSystem;
  const isElementaryFlow = referenceProduct?.type === ReferenceProductType.ElementaryFlow;

  const [ itemImpact, itemImpactExponent ] = getDecimalDisplayValue({ value: inventoryItem?.impact?.amount || 0, decimalViewType: DecimalViewType.NumericValue })
  const [ itemAmount ] = getDecimalDisplayValue({ value: inventoryItem.amount || 0, decimalViewType: DecimalViewType.NumericValue })
  const [ impactPerDataset ] = getDecimalDisplayValue({ value: selectedEntityImpact?.amount || 0, decimalViewType: DecimalViewType.NumericValue })

  const previewHeader = inventoryItem.amount
    ? t(
      'labels.unitOfEntityWithAmount',
      {
        ns: 'common',
        amount: itemAmount,
        unit,
        name
      }
    )
    : name

  useEffect(() => {
    if (!textRef.current) return

    const element = textRef.current
    if (element.scrollHeight > element.clientHeight) {
      element.style.webkitLineClamp = '3'
      setIsOverflowing(true)
    } else {
      setIsOverflowing(false)
    }
  }, [ selectedProduct?.name ])

  const handleChangeReference = (event: any) => {
    event.stopPropagation();

    if (hasInventory) return;

    datasetNavigate();
    closeSidebar();
  }

  const handleRemoveReference = (event: any) => {
    event.stopPropagation();
    const removeDetails = isCustomImpact ? { showDeleteCustomImpact: true } : { showDeleteReferenceProduct: true }
    updateDashboard(removeDetails)
  }

  const openImpactDetail = (event: any) => {
    event.stopPropagation()
    if (!referenceProduct || isCustomImpact) return

    closeSidebar();
    const selectedDatasetViewType = referenceProduct?.type === ReferenceProductType.ProductSystem
      ? ImpactDatasetViewType.DatabaseSearch
      : ImpactDatasetViewType.ElementaryFlow
    navigateToDatasetDetail({
      viewType: selectedDatasetViewType,
      selectedReferenceProduct: referenceProduct
    })
  }

  const generateInfoMessage = () => (
    <div className="flex flex-column gap-2">
      <div className="flex font-bold">
        <div className="flex flex-grow-1">{t('labels.calculation')}</div>
      </div>
      <div className="flex font-normal">
        {t('labels.calculation', {
          context: referenceProduct?.type,
          itemUnit: selectedProduct.unit,
          itemName: selectedProduct.name,
          datasetUnit: referenceProduct?.referenceUnit?.name
        })}
      </div>
    </div>
  )

  const getProductNameTooltip = () => (
    <TooltipOverflowContent> { previewHeader } </TooltipOverflowContent>
  )

  const getImpactTooltip = () => {
    const impactUnit = inventoryItem?.impact?.unit;
    return (
      <TooltipOverflowContent>
        <div className="flex flex-column px-3">
          { selectedEntityImpact?.amount && (
            <div className="flex justify-content-start py-2 text-sm">
              {
                t('labels.impactPreview', {
                  context: 'itemImpact',
                  amount: itemAmount,
                  impactUnit,
                  itemImpact,
                  unit,
                  name
                })
              }
            </div>
          )}
          { selectedEntityImpact?.amount && (
            <div className="flex justify-content-start py-2 text-sm">
              {
                t('labels.impactPreview', {
                  context: 'impactPerDataset',
                  impactUnit,
                  impactPerDataset,
                  name,
                  unit
                })
              }
            </div>
          )}

          <div className="flex flex-column justify-content-start py-2 gap-1">
            <div className="flex text-xs text-gray-200">
              { t('labels.impactPreview', { context: 'impactCategory' }) }
            </div>
            <div className="flex text-sm">
              { impactCategory?.name }
            </div>
          </div>

          <div className="flex flex-column justify-content-start py-2 gap-1">
            <div className="flex text-xs text-gray-200">
              { t('labels.impactPreview', { context: 'impactAssessmentMethod' }) }
            </div>
            <div className="flex text-sm">
              { impactMethod?.name }
            </div>
          </div>
        </div>
      </TooltipOverflowContent>
    )
  }

  const getDatasetTooltip = (name: string) => (
    <div className="flex flex-column w-25rem px-3">
      <div className="flex justify-content-start py-2 text-sm">
        {
          t('labels.impactPreview', {
            context: referenceProduct?.type,
            datasetUnit: referenceProduct?.referenceUnit?.name,
            impactUnit: inventoryItem?.impact?.unit,
            impactPerDataset,
            name
          })
        }
      </div>

      <div className="flex flex-column justify-content-start py-2 gap-1">
        <div className="flex text-xs text-gray-200">
          { t('labels.impactPreview_datasetName', { context: referenceProduct?.type }) }
        </div>
        <div className="flex text-sm">
          { name }
        </div>
      </div>

      { referenceProduct?.databaseName && (
        <div className="flex flex-column justify-content-start py-2 gap-1">
          <div className="flex text-xs text-gray-200">
            { t('labels.impactPreview', { context: 'databaseName'}) }
          </div>
          <div className="flex text-sm">
            { referenceProduct.databaseName }
          </div>
        </div>
      )}
      { referenceProduct?.referenceUnit?.name && (
        <div className="flex flex-column justify-content-start py-2 gap-1">
          <div className="flex text-xs text-gray-200">
            { t('labels.impactPreview_datasetUnit', { context: referenceProduct?.type }) }
          </div>
          <div className="flex text-sm">
            { referenceProduct?.referenceUnit?.name }
          </div>
        </div>
      )}
      { referenceProduct?.location?.name && (
        <div className="flex flex-column justify-content-start py-2 gap-1">
          <div className="flex text-xs text-gray-200">
            { t('labels.impactPreview', { context: 'location' }) }
          </div>
          <div className="flex text-sm">
            { referenceProduct.location.name }
          </div>
        </div>
      )}
    </div>
  )

  const datasetPreviewTemplate = (icon: any, label: string) => (
    <div className="flex w-full gap-2" onMouseEnter={() => setEnableChangeDataset(!hasInventory)} onMouseLeave={() => setEnableChangeDataset(false)}>
      { !hasInventory && <Tooltip target=".impact-preview-dataset-label" position="left">{ getDatasetTooltip(label) }</Tooltip> }
      <div className={classNames('flex align-items-start text-primary-500', { 'pt-1': isProductSystem })}>
        <FontAwesomeIcon icon={icon} className="mr-1" />
      </div>
      <div
        ref={datasetRef}
        onClick={openImpactDetail}
        data-testid="impact-preview-datasetLabel"
        data-cy="product-reference-name"
        className={classNames('flex align-items-center text-sm line-height-3 flex-grow-1 impact-preview-dataset-label', {
          'hover:text-primary-500 cursor-pointer': !hasInventory
        })}
      >
        { label }
      </div>
      { !hasInventory && (
        <div className="flex flex-none text-base gap-2 w-2rem">
          { enableChangeDataset && editableSidebar && (
            <>
              <FontAwesomeIcon data-cy="edit-reference" data-testid="impact-preview-datasetEdit" icon={faPen} className="text-gray-300 hover:text-primary-500 cursor-pointer" onClick={handleChangeReference} />
              <FontAwesomeIcon data-cy="remove-reference" data-testid="impact-preview-datasetRef" icon={faTrash} className="text-red-300 hover:text-primary-500 cursor-pointer" onClick={handleRemoveReference} />
            </>
          )}
        </div>
      )}
    </div>
  )

  const renderImpactPreview = () => {
    if (!selectedProduct) return

    return (
      <div data-testid="impact-preview-impactWrapper" className="flex w-full">
        { inventoryItem.impact?.amount && (
          <>
            <Tooltip target=".impact-preview-value" position="left">{getImpactTooltip()}</Tooltip>
            <div className="flex flex-column w-full align-items-center justify-content-end text-2xl font-semibold gap-1">
              <div data-testid="impact-preview-itemImpact" className="flex w-full">
                <div ref={impactRef} className="flex flex-wrap align-items-center line-height-2 impact-preview-value">
                  { itemImpactExponent ? (
                    <>
                      { itemImpact }
                      {' '}
                      &middot; 10
                      <sup>{ itemImpactExponent }</sup> 
                    </>
                  ) : <>{ itemImpact } </> }
                  { inventoryItem.impact?.unit }
                </div>
                { (isElementaryFlow || isProductSystem) && (
                  <FontAwesomeIcon
                    onClick={() => setIsCalculationShown(!isCalculationShown)}
                    icon={faCircleInfo}
                    data-testid="impact-preview-iconConversion"
                    className={classNames('text-sm text-gray-300 hover:text-primary-500 cursor-pointer pr-1 mt-2 ml-2', { 'text-primary-500': isCalculationShown })}
                  />
                )}
              </div>
              { (isElementaryFlow || isProductSystem) && isCalculationShown && (
                <div className="flex w-full">
                  <Message severity="info" data-testid="impact-preview-iconConversionMsg" content={generateInfoMessage()} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    )
  }

  const renderDatasetPreview = () => {
    if (hasInventory) {
      return datasetPreviewTemplate(faChartBar, t('labels.impactPreview', { context: 'calculatedFromSubItem' }));
    }

    if (isCustomImpact) {
      return datasetPreviewTemplate(faPenField, t('labels.customImpact'));
    }

    const { name, type } = referenceProduct || {};

    if (name && type) {
      const icon = type === ReferenceProductType.ProductSystem ? faDatabase : faCircleNodes;

      return datasetPreviewTemplate(icon, name);
    }

    return null;
  };

  if (!hasInventory && productHasNoImpact) {
    return (
      <div className="flex flex-column w-full">
        { textRef.current && <Tooltip target={textRef.current} onBeforeShow={() => isOverflowing} position="left">{getProductNameTooltip()}</Tooltip> }
        <PreviewHeader data-cy="selected-product-name-text" ref={textRef} className="w-full text-gray-700 text-base font-medium px-4">
          { previewHeader }
        </PreviewHeader>

        <div className="flex align-items-start flex-column w-full px-4 pt-3">
          <Button
            label="Add impact"
            onClick={handleChangeReference}
            className="p-button-primary h-2rem text-sm font-normal"
          />
        </div>
      </div>
    )
  }

  return (
    <ImpactPreviewWrapper data-testid="impact-preview-wrapper" className="flex flex-column w-full">
      { textRef.current && <Tooltip target={textRef.current} onBeforeShow={() => isOverflowing} position="left">{getProductNameTooltip()}</Tooltip> }

      <PreviewHeader data-cy="selected-product-name-text" data-testid="impact-preview-header" ref={textRef} className="w-full text-gray-700 text-base font-medium px-4">
        { previewHeader }
      </PreviewHeader>

      <div className="flex align-items-start flex-column w-full px-4">
        { selectedEntityImpact?.status === Status.Pending && <i className="pi pi-spin pi-spinner text-lg text-primary-500" /> }
        { selectedEntityImpact?.status === Status.NotCompatible && <FontAwesomeIcon icon={faExclamationTriangle} className="text-2xl text-orange-700 pr-1 mt-2 ml-2" /> }
        { selectedEntityImpact?.status === Status.Ok && renderImpactPreview() }
      </div>

      <div className="flex text-xs px-4 pt-3 text-gray-300">
        { (isProductSystem || isElementaryFlow) && t('labels.used', { context: referenceProduct?.type }) }
      </div>
      <div className="flex align-items-start flex-column px-4 w-full">
        { renderDatasetPreview() }
      </div>

      { isProductSystem && selectedEntityImpact?.status === Status.Ok && selectedEntityImpact?.amount && (
        <>
          { referenceProduct?.referenceUnit && (
            <div data-testid="impact-preview-perUnitLabel" className="flex text-xs pl-6 text-gray-300">
              { t('labels.impact', { context: 'perUnit', ns: 'common', unit: referenceProduct.referenceUnit.name }) }
            </div>
          ) }
          <div data-testid="impact-preview-impactPerUnit" className="flex justify-content-start text-sm pl-6">
            <DecimalPointComponent value={selectedEntityImpact.amount} enableTooltip decimalViewType={DecimalViewType.NumericValue} />
            <div className="flex align-items-center">{ selectedEntityImpact?.unit }</div>
          </div>
        </>
      )}
    </ImpactPreviewWrapper>
  );
}
