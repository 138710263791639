import React, { useContext, useState } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useTranslation } from 'react-i18next'

import { ILifecycle } from '../../model'
import { TableWrapperComponent } from '../../shared/component/general/table-wrapper.component'
import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { getSegmentTrack } from '../../shared/util/segment'
import { TrackEventType } from '../../shared/enum/track-events'
import { TableHeaderComponent } from '../../shared/component/overview/table-header-template.component'
import { TableBodyTemplateComponent } from '../component/view-template/table-body-template.component'
import { LifecycleOverviewTableColumn } from '../../shared/enum'
import { TLifecycleContext } from '../interface/lifecycle-context.type'
import LifecycleContext from '../provider/context/lifecycle.context'
import { setOverviewSetting } from '../../shared/util/overview'
import { IDashboardContext } from '../../shared/interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { LifeCycleSortField, SortingOrder } from '../../../__generated__/graphql'

type TableListLayoutProps = {
  lifeCycles: ILifecycle[]
}

export const TableListLayout = ({
  lifeCycles = [],
}: TableListLayoutProps) => {
  const { t } = useTranslation([ 'common' ])
  const { selectedSortBy, selectedSortOrder, updateLifecycle } = useContext<TLifecycleContext>(LifecycleContext)
  const [ trackEventInSegment ] = getSegmentTrack()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const navigate = useNavigate()
  const { addToBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const [ mouseOverOn, setMouseOverOn ] = useState<string | null>(null)

  const handleSortTable = (sortBy: LifeCycleSortField, sortOrderTo: SortingOrder) => {
    const sortUpdate = { selectedSortBy: sortBy, selectedSortOrder: sortOrderTo }
    updateLifecycle(sortUpdate)
    setOverviewSetting(sortUpdate)
  }

  const onRowMouseEnter = (id: string) => {
    setMouseOverOn(id)
  }

  const onRowMouseLeave = () => {
    setMouseOverOn(null)
  }

  const navigateToLifecycleDetail = (event?: any) => {
    const { data: { id } } = event
    trackEventInSegment(TrackEventType.VIEWED_LIFECYCLE)
    addToBackToList(t('labels.lifecycle', { count: 2, ns: 'common' }))
    navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${id}/detail/`)
  }

  return (
    <TableWrapperComponent>
      <DataTable
        value={lifeCycles}
        emptyMessage=""
        onRowClick={navigateToLifecycleDetail}
      >
        <Column
          field="name"
          className="border-round-left-lg"
          header={(
            <TableHeaderComponent<LifeCycleSortField>
              label={t('labels.tableHeader', { context: 'name' })}
              sortBy={LifeCycleSortField.Name}
              onSortClick={handleSortTable}
              selectedSortBy={selectedSortBy}
              selectedSortOrder={selectedSortOrder}
            />
          )}
          body={(item: ILifecycle) => <TableBodyTemplateComponent onRowMouseEnter={onRowMouseEnter} onRowMouseLeave={onRowMouseLeave} column={LifecycleOverviewTableColumn.Name} value={{ lifecycle: item, isMouseOver: item.id === mouseOverOn }} />}
        />
        <Column
          field="updatedAt"
          header={(
            <TableHeaderComponent
              label={t('labels.tableHeader', { context: 'updated' })}
              sortBy={LifeCycleSortField.Updated}
              onSortClick={handleSortTable}
              selectedSortBy={selectedSortBy}
              selectedSortOrder={selectedSortOrder}
            />
          )}
          body={(item: ILifecycle) => <TableBodyTemplateComponent onRowMouseEnter={onRowMouseEnter} onRowMouseLeave={onRowMouseLeave} column={LifecycleOverviewTableColumn.LastChanged} value={{ lifecycle: item }} />}
          style={{ width: '140px' }}
        />
        <Column
          field="createdAt"
          header={(
            <TableHeaderComponent
              label={t('labels.tableHeader', { context: 'created' })}
              sortBy={LifeCycleSortField.Created}
              onSortClick={handleSortTable}
              selectedSortBy={selectedSortBy}
              selectedSortOrder={selectedSortOrder}
            />
          )}
          body={(item: ILifecycle) => <TableBodyTemplateComponent onRowMouseEnter={onRowMouseEnter} onRowMouseLeave={onRowMouseLeave} column={LifecycleOverviewTableColumn.Created} value={{ lifecycle: item }} />}
          style={{ width: '140px' }}
        />
        <Column
          field="description"
          className="border-round-right-lg"
          header={<TableHeaderComponent label={t('labels.tableHeader', { context: 'description' })} />}
          body={(item: ILifecycle) => <TableBodyTemplateComponent onRowMouseEnter={onRowMouseEnter} onRowMouseLeave={onRowMouseLeave} column={LifecycleOverviewTableColumn.Description} value={{ lifecycle: item }} />}
        />
      </DataTable>
    </TableWrapperComponent>
  )
}
