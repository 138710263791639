import React, { useContext } from 'react'
import { Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenField } from '@fortawesome/pro-regular-svg-icons'
import CustomImpactFormInfo from '../../../custom-impact-form/customImpactFormInfo.container'
import compose from '../../../../../utils/compose'
import { withTranslation } from 'react-i18next'

import { useDatasetNavigate } from '../../../../../v1/impact-dataset/hook/use-dataset-navigate'

const CustomImpact = props => {
  const {
    t,
    setIsImpactSelectorDialogShowedAction,
    isDetailsPanelReadOnly,
    selectedProduct
  } = props
  const { datasetNavigate } = useDatasetNavigate(selectedProduct)

  return <React.Fragment>
    <p className="custom_impact_exist-message" >
      <FontAwesomeIcon icon={faPenField} className="mr-1" />
      <Tooltip
        placement={'bottom'}
        title={<CustomImpactFormInfo/>}
      >
        <span data-testid="custom-impact">
          {t('model.custom_impact')}
        </span>
      </Tooltip>
      <span className="custom_impact-edit_wrapper">
        {!isDetailsPanelReadOnly && <EditOutlined
          className="custom_impact-edit"
          onClick={datasetNavigate}/>
        }
      </span>
    </p>
  </React.Fragment>
}

export { CustomImpact }
export default compose(
  withTranslation()
)(CustomImpact)
