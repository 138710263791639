import React, { useContext, useEffect } from 'react'
import { TProductDetailContext } from '../interface/product-detail-context.type'
import ProductDetailContext from '../provider/context/product-detail.context'
import TreeWrapperLayout from '../layout/tree-wrapper.layout'
import { MenuAction } from '../enum/menu-action'
import { store } from '../../../configureStore'
import { setIsCreateNewInventoryItemAction, setIsDetailsPanelOpenAction } from '../../../redux/actions/flags.actions'
import { setSelectedProductAction } from '../../../redux/actions/global.actions'
import { TrackEventType } from '../../shared/enum/track-events'
import { getSegmentTrack } from '../../shared/util/segment'
import { DeleteTreeItemContainer } from '../container/dialog/delete-tree-item.container'
import { DeleteReferenceProductContainer } from '../container/dialog/delete-reference-product.container'
import { DeleteCustomImpactContainer } from '../container/dialog/delete-custom-impact.container'

export const ProductModelComponent = () => {
  const {
    updateProductDetail,
    showDeleteTreeItem,
    selectedAction,
    selectedProduct,
    refetchInventory,
    refetchInventoryItems = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const [ trackEventInSegment ] = getSegmentTrack()

  useEffect(() => {
    if (selectedAction === MenuAction.AddTreeItem) {
      addTreeItemHandler()
    }
  }, [ selectedAction, selectedProduct ])

  // TODO : Added for compatibility with v0.9
  const addTreeItemHandler = () => {
    store.dispatch(setSelectedProductAction(selectedProduct))
    store.dispatch(setIsCreateNewInventoryItemAction(true))
    store.dispatch(setIsDetailsPanelOpenAction(true))
  }

  const afterDeleteTreeItem = () => {
    refetchInventory && refetchInventory()
    refetchInventoryItems()
    trackEventInSegment(TrackEventType.DELETED_SUBITEM)
    updateProductDetail({ hasInventoryChanged: true })
  }

  return (
    <>
      <TreeWrapperLayout />

      { showDeleteTreeItem && <DeleteTreeItemContainer afterDeleteTreeItem={afterDeleteTreeItem} /> }
      <DeleteReferenceProductContainer afterDeleteTreeItem={afterDeleteTreeItem} />
      <DeleteCustomImpactContainer afterDeleteTreeItem={afterDeleteTreeItem} />
    </>
  )
}
