import React, {
  useContext, useRef, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { classNames } from 'primereact/utils'
import styled, { createGlobalStyle } from 'styled-components'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { OverlayPanel } from 'primereact/overlaypanel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faTrash, faSidebarFlip } from '@fortawesome/pro-regular-svg-icons'
import { faArrowUpRight } from '@fortawesome/pro-light-svg-icons'

import { MenuAction } from '../../enum/menu-action'
import { MenuItemComponent } from '../../../shared/component/general/menu-item.component'
import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { TInventoryTreeItem } from '../../../shared/util/transform'
import { TLifecycleDetailContext } from '../../../lifecycle/interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../../../lifecycle/provider/context/lifecycle-detail.context'
import { useProductNavigate } from '../../hook/use-product-navigate'
import { AddSubItemFormComponent } from '../../component/tree/tree-add-subitem-form.component'
import { AddSubItemFormContainer } from '../model/tree-add-subitem-form.container'

const MenuTreeItemContainerWrapper = styled.div`
  .p-button-outlined {
    width: 1.5rem;
    height: 1.5rem;
    box-shadow: none !important;
  }

  .btn-menu-item {
    color: var(--gray-300);
    border-color: var(--gray-300);

    &:hover {
      color: var(--primary-500);
      border-color: var(--primary-500);
    }
  }
`

const GlobalStyle = createGlobalStyle`
  .popup_menu_create {
    width: 700px;
  }
`

type MenuTreeItemContainerProps = {
  node: TInventoryTreeItem
  onNodeClick?: Function,
  onAddSubitem?: Function,
  isNodeMinWidth?: Function,
}
export const MenuTreeItemContainer = ({
  node,
  onNodeClick,
  onAddSubitem = () => {},
  isNodeMinWidth = () => {}
}: MenuTreeItemContainerProps) => {
  const { t } = useTranslation([ 'common', 'product' ])

  const toast = useRef<Toast>(null)
  const [ nodeItem, setNodeItem ] = useState<any>(null)
  const { lifecycleDetail } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const {
    productInventory,
    selectedKey,
    updateProductDetail,
    getCreateItemNode = () => {},
    setLifecycleSelectedKeyAndPhase = () => {}
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const menu = useRef<Menu>(null)
  const addSubItemPanelRef = useRef<OverlayPanel>(null)

  const { key, data: { inventoryItem = null } = {} } = node
  const { product: { id: selectedProductId = null } = {} } = inventoryItem || {}
  const { productNavigate } = useProductNavigate()
  const isSelected = selectedKey === node.key

  const onMouseEnter = (event: any) => {
    event.stopPropagation()
    menu.current?.show(event)
  }
  const onMouseLeave = (event: any) => {
    event.stopPropagation()
    menu.current?.hide(event)
  }

  const executeCommand = (event: any) => {
    event.originalEvent.stopPropagation()
    const { action: selectedAction } = event.item
    const backLabel = lifecycleDetail ? lifecycleDetail.name : productInventory?.product?.name
    setLifecycleSelectedKeyAndPhase(key)

    switch (selectedAction) {
    case MenuAction.DeleteTreeItem:
      updateProductDetail({ showDeleteTreeItem: true, selectedNode: node, selectedAction })
      break
    case MenuAction.OpenTreeItem:
      updateProductDetail({ hasInventoryChanged: true })
      productNavigate({ backLabel, selectedProductId })
      break
    case MenuAction.OpenProductDetail:
      onNodeClick && onNodeClick({ ...event, node })
      updateProductDetail({ selectedKey: key })
      break
    default:
      break
    }
  }

  const handleAddSubItem = (event: any) => {
    event.stopPropagation()

    if (isNodeMinWidth()) {
      addSubItemPanelRef.current?.toggle(event)

      const createNodeItem = getCreateItemNode(node)

      setNodeItem(createNodeItem)
      setTimeout(() => {
        const menuEl = addSubItemPanelRef.current?.getElement();
        if (menuEl) {
          menuEl.style.left = `${menuEl.offsetLeft + 200}px`; // Move 20px to the right
        }
      }, 0);
    } else {
      onAddSubitem(event)
    }
  }

  const items = [
    {
      action: MenuAction.OpenProductDetail,
      label: t('actions.open', { context: 'detailPanel', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faSidebarFlip} className={classNames('mr-1 open-product-detail', { 'text-primary-500': isSelected })} />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.OpenTreeItem,
      label: t('actions.open', { context: 'item', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faArrowUpRight} className="text-sm mr-1" />,
      command: executeCommand,
      template: MenuItemComponent
    },
    {
      action: MenuAction.DeleteTreeItem,
      label: t('actions.delete', { context: 'item', ns: 'product' }),
      icon: <FontAwesomeIcon icon={faTrash} className="text-sm mr-1" />,
      textColor: '--red-500',
      command: executeCommand,
      template: MenuItemComponent
    }
  ]

  const afterAddSubItem = () => {
    addSubItemPanelRef.current?.hide()
  }

  return (
    <>
      <GlobalStyle />

      <MenuTreeItemContainerWrapper className="flex flex-none w-full gap-2 justify-content-end">
        <Menu model={items} popup ref={menu} id="popup_menu" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />

        <OverlayPanel ref={addSubItemPanelRef} dismissable>
          <AddSubItemFormContainer
            node={nodeItem}
            afterAddSubItem={afterAddSubItem}
            onEscape={() => addSubItemPanelRef.current?.hide()}
            ItemComponent={AddSubItemFormComponent}
          />
        </OverlayPanel>

        <Button
          data-testid="btn-add-subItem"
          icon="pi pi-plus"
          className="p-button-outlined btn-menu-item"
          data-cy="add-subitem-action"
          tooltip={t('actions.add', { context: 'subitem', ns: 'product' })}
          tooltipOptions={{ position: 'bottom' }}
          onClick={handleAddSubItem}
        />

        <Button
          icon={<FontAwesomeIcon icon={faEllipsis} className="text-lg" />}
          data-testid="card-menu-more"
          data-cy="card-menu-button"
          onClick={(event: any) => event.stopPropagation()}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className="p-button-outlined btn-menu-item"
          aria-controls="popup_menu"
          aria-haspopup
        />

        <Toast data-testid="card-menu-status" ref={toast} position="top-right" />
      </MenuTreeItemContainerWrapper>
    </>
  )
}
