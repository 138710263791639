import React, { useContext } from 'react'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { useTranslation } from 'react-i18next'
import { TImpactDatasetContext, TPageData } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { setSelectedDatabasesStorageItem } from '../../../shared/util/impact'
import { FacetValue } from '../../../../__generated__/graphql'

export const DatabaseFilterListComponent = () => {
  const { t } = useTranslation([ 'impact-dataset' ])
  const {
    updateFilterValue,
    updateImpactDataset,
    getDatasetPageData = () => ({} as TPageData),
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const { databaseList, selectedDatabases = [] } = getDatasetPageData()

  const updateSelectedDatabases = (selectedDatabasesNames: string[] = []) => {
    setSelectedDatabasesStorageItem(selectedDatabasesNames)
    updateImpactDataset({ hasActiveDatabaseFilter: true })
    updateFilterValue({ selectedDatabases: selectedDatabasesNames })
  }

  const onDatabaseChange = (event: any) => {
    const selectedDatabaseNames = [ ...selectedDatabases ]
    const selectedDatabaseIndex = selectedDatabaseNames.findIndex(name => name === event.value.name)

    if (event.checked) {
      selectedDatabaseNames.push(event.value.name)
    } else {
      selectedDatabaseNames.splice(selectedDatabaseIndex, 1)
    }

    updateSelectedDatabases(selectedDatabaseNames)
  }

  const isChecked = (databaseName: string) => selectedDatabases.includes(databaseName)
  const showCounts = selectedDatabases.length === 0

  const getDatabaseLabel = (database: FacetValue) => {
    const isSelected = isChecked(database.name)
    return (
      <div className="flex align-items-center text-sm text-grey-500 hover:text-primary-500">
        <span>{database.name}</span>
        {(showCounts || isSelected) && <span className="text-xs ml-1">({database.count})</span>}
      </div>
    )
  }

  return (
    <div data-testid="database-filter-wrapper" className="flex flex-column w-full">

      <ul className="w-full">
        { databaseList?.map((database, index) => (
          <li
            data-testid={`database-item-${index}`}
            key={`database-item-${index}`}
            className="flex align-items-center gap-2 w-full py-1"
          >
            <Checkbox
              inputId={database.name}
              name="database"
              value={database}
              onChange={onDatabaseChange}
              data-testid={`database-checkbox-${index}`}
              checked={isChecked(database.name)}
            />
            <label
              htmlFor={database.name}
              className="line-height-2 text-sm text-gray-500"
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
              }}
            >
              {getDatabaseLabel(database)}
            </label>
          </li>
        )) }
      </ul>

      <div className="flex justify-content-between w-full border-top-1 border-gray-100 pt-3 mt-3">
        <Button
          label={t('labels.clearFilters')}
          onClick={() => updateSelectedDatabases([])}
          disabled={selectedDatabases.length === 0}
          className="p-button-outlined p-button-plain h-2rem"
        />
      </div>
    </div>
  )
}
