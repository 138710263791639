import React, {
  useContext, useRef, useState
} from 'react'
import { classNames } from 'primereact/utils'
import { createGlobalStyle } from 'styled-components'

import { faChevronDown, faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { OverlayPanel } from 'primereact/overlaypanel'

import { TImpactDatasetContext, TPageData } from '../../interface/impact-dataset.context'
import ImpactDatasetContext from '../../provider/context/impact-dataset.context'
import { SortTogglerComponent } from './sort-toggler.component'
import { DatasetSortField, SortingOrder } from '../../../../__generated__/graphql'
import { PropertyFilterListComponent } from './property-filter-list.component'
import { useClickOutside } from '../../../shared/hook/use-outside-click'

const GlobalStyle = createGlobalStyle`
  .p-overlaypanel{
    min-width: 300px;
  }
  .p-overlaypanel-content {
    padding: 1rem !important;
  }
`
type TDatabaseFilterComponent = {
  label?: string
}

export const PropertyFilterComponent = ({
  label
}: TDatabaseFilterComponent) => {
  const overlayPanel = useRef<OverlayPanel>(null)
  const panelContainerRef = useRef<HTMLDivElement>(null)
  const {
    updateFilterValue,
    getDatasetPageData = () => ({} as TPageData),
  } = useContext<TImpactDatasetContext>(ImpactDatasetContext)
  const {
    selectedSortBy,
    selectedSortOrder,
    selectedProperties = []
  } = getDatasetPageData()
  const [ addOutsideClickListener, setAddOutsideClickListener ] = useState<boolean>(false)
  useClickOutside(panelContainerRef, () => overlayPanel?.current?.hide(), addOutsideClickListener)

  const isSortByProperty = selectedSortBy === DatasetSortField.FlowProperty

  const handleSortingChange = (sortOrder: any) => {
    if (!sortOrder) {
      updateFilterValue({ selectedSortBy: undefined, selectedSortOrder: undefined })
    } else {
      updateFilterValue({ selectedSortBy: DatasetSortField.FlowProperty, selectedSortOrder: sortOrder })
    }

    overlayPanel?.current?.hide()
  }

  return (
    <>
      <GlobalStyle />
      <OverlayPanel ref={overlayPanel} onShow={() => setAddOutsideClickListener(true)} onHide={() => setAddOutsideClickListener(false)}>
        <div ref={panelContainerRef} className="flex flex-column w-full">
          <div className="flex w-full pb-3 line-height-1 text-sm font-medium border-bottom-1 border-gray-100">
            <div data-testid="filter-header-label" className="flex align-items-center mr-2 text-gray-500">{ label }</div>
            {/* Uncomment this icon when we will have content for it */}
            {/* <FontAwesomeIcon icon={faCircleInfo} className="text-basis text-primary-500" /> */}
            <div className="flex flex-grow-1 justify-content-end">
              <SortTogglerComponent
                onViewChange={handleSortingChange}
                selectedSortOrder={selectedSortBy === DatasetSortField.FlowProperty ? selectedSortOrder : undefined}
              />
            </div>
          </div>
          <div className="flex align-items-center pt-3">
            <PropertyFilterListComponent />
          </div>
        </div>
      </OverlayPanel>

      <span
        data-testid="table-header-property-label"
        onClick={(event: any) => overlayPanel.current?.toggle(event)}
        className={classNames('mr-1 text-gray-300 text-sm table-header-label cursor-pointer hover:text-primary-500', {
          'text-primary-500': selectedProperties.length > 0 || isSortByProperty
        })}
      >
        { label }
        { !isSortByProperty
          ? <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
          : (
            <>
              { selectedSortOrder === SortingOrder.Asc && <FontAwesomeIcon data-testid="property-filter-asc" className="ml-1 text-sm" icon={faArrowDown} /> }
              { selectedSortOrder === SortingOrder.Desc && <FontAwesomeIcon data-testid="property-filter-desc" className="ml-1 text-sm" icon={faArrowUp} /> }
            </>
          )}
      </span>
    </>
  )
}
