import React, { useContext } from 'react'

import { ProductWrapperLayout } from '../../layout/product-wrapper.layout'
import { ProductDetailContainer } from '../../../product/container/product-detail.container'
import { TAnalysisContext } from '../../interface/analysis.context'
import AnalysisContext from '../../provider/context/analysis.context'
import { ComparisonEntity } from '../../enum/entity'
import { ProductDetailProvider } from '../../../product/provider/product-detail.provider'
import { ProductModelContainer } from '../../../product/container/product-model.container'
import { AnalysisMode } from '../../enum/analysis'

import { DeleteTreeItemContainer } from '../../../product/container/dialog/delete-tree-item.container'

import { getSegmentTrack } from '../../../shared/util/segment'
import { TrackEventType } from '../../../shared/enum/track-events'
import { DeleteReferenceProductContainer } from '../../../product/container/dialog/delete-reference-product.container'

type TModelPanelComponentProps = {
  entity?: ComparisonEntity
}
export const ModelPanelComponent = ({
  entity = ComparisonEntity.Primary
}: TModelPanelComponentProps) => {
  const {
    secondaryEntityId = '',
    analysisMode,
    secondaryInventoryItemsRefetch,
    secondaryUpdateDetail,
    primaryUpdateDetail,
  } = useContext<TAnalysisContext>(AnalysisContext)

  const isPrimary = entity === ComparisonEntity.Primary
  const isComparison = analysisMode === AnalysisMode.Comparison

  const getModel = () => (
    <ProductModelContainer>
      <ProductWrapperLayout entity={entity} />
    </ProductModelContainer>
  )

  const [ trackEventInSegment ] = getSegmentTrack()

  const afterDeleteTreeItem = () => {
    secondaryInventoryItemsRefetch && secondaryInventoryItemsRefetch()
    primaryUpdateDetail && primaryUpdateDetail({ hasInventoryChanged: true })
    secondaryUpdateDetail && secondaryUpdateDetail({ hasInventoryChanged: true })
    trackEventInSegment(TrackEventType.DELETED_SUBITEM)
  }

  return (
    <>
      { !isPrimary && secondaryEntityId
        ? (
          <ProductDetailProvider
            readonlyInventory={isComparison}
            showInventoryHeader={false}
            noInventoryImpactBar
            analysisMode={analysisMode}
          >
            <ProductDetailContainer productId={secondaryEntityId}>
              { getModel() }
            </ProductDetailContainer>

            <DeleteTreeItemContainer afterDeleteTreeItem={afterDeleteTreeItem} />

            <DeleteReferenceProductContainer afterDeleteTreeItem={afterDeleteTreeItem} />

          </ProductDetailProvider>
        )
        : <>{ getModel() }</>}
    </>
  )
}
