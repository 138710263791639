import { gql } from '@apollo/client'
import referenceProduct from '../fragment/referenceProduct'
import minimalProductFragment from '../fragment/minimalProduct'

export default gql`
    query inventory($productID: ID!){
        inventory(productID: $productID) {
            product {
                ...minimalProductFragment
                epdModule {
                    code
                }
                hasInventory
                customImpacts {
                    amount
                    impactCategoryID
                    impactMethodID
                    excludeLT
                }
                tags {
                    id
                    name
                    color
                }
                referenceProduct {
                    ...referenceProduct
                }
                productProperties {
                    referenceProperty {
                        id
                        name
                    }
                    conversionFactor
                    referenceUnit {
                        id
                        name
                    }
                }
                lifecycles {
                    id
                    name
                }
                scenarios{
                    id
                    name
                }
                folder{
                    id
                    name
                }
            }
            inventoryItems {
                nodeId
                parentNode
                inventoryItem {
                    amount
                    conversionID
                    impact {
                        amount
                        unit
                        status
                    }
                    product{
                        ...minimalProductFragment
                        referenceProduct {
                            id
                        }
                        customImpacts {
                            amount
                        }
                    }
                    phase{
                        id
                        name
                    }
                }
            }
            leafInventoryItems {
                amount
                conversionID
                impact {
                    amount
                    unit
                    status
                }
                product {
                    ...minimalProductFragment
                }
                phase{
                    id
                    name
                }
            }
            totalImpact {
                amount
                unit
                status
            }
        }
    }
    ${minimalProductFragment}
    ${referenceProduct}
`
