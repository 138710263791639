import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/pro-regular-svg-icons'

import { TProductDetailContext } from '../../interface/product-detail-context.type'
import ProductDetailContext from '../../provider/context/product-detail.context'
import { AddNodeWrapperLayout } from '../../layout/add-node-wrapper.layout'
import { setSelectedProductAction } from '../../../../redux/actions/global.actions'
import { store } from '../../../../configureStore'

import { useDatasetNavigate } from '../../../impact-dataset/hook/use-dataset-navigate'

export const AddImpactNodeComponent = () => {
  const {
    productInventory: { product } = {},
  } = useContext<TProductDetailContext>(ProductDetailContext)
  const { t } = useTranslation([ 'product' ])
  const { datasetNavigate } = useDatasetNavigate(product!)

  const addImpact = () => {
    // TODO : From v0.9 : To be refactored
    store.dispatch(setSelectedProductAction(product))
    datasetNavigate()
  }

  return (
    <AddNodeWrapperLayout onClick={addImpact} dataTestId="add-impact-node">
      <div className="flex-none flex align-items-center justify-content-center">
        <FontAwesomeIcon data-testid="add-impact-node-icon" icon={faDatabase} className="text-base" />
      </div>
      <div data-testid="add-impact-node-text" className="flex-grow-1 flex align-items-center text-base justify-content-start line-height-4">
        { t('actions.add', { context: 'impact' }) }
      </div>
    </AddNodeWrapperLayout>
  )
}

