import { gql } from '../../../__generated__'

export const INVENTORY_ROOT_PRODUCT = gql(`
    query inventoryRootProduct($productID: ID!){
        inventory(productID: $productID) {
            product {
                ...minimalProductFragment
                epdModule {
                    code
                }
                hasInventory
                customImpacts {
                    amount
                    impactCategoryID
                    impactMethodID
                    excludeLT
                }
                tags {
                    id
                    name
                    color
                }
                referenceProduct {
                    ...referenceProduct
                }
                productProperties {
                    referenceProperty {
                        id
                        name
                    }
                    conversionFactor
                    referenceUnit {
                        id
                        name
                    }
                }
                lifecycles {
                    id
                    name
                }
                scenarios{
                    id
                    name
                }
                folder{
                    id
                    name
                }
                labels{
                    id
                }
            }
        }
    }
`)

export const INVENTORY_ITEMS = gql(`
  query inventory($productID: ID!){
    inventory(productID: $productID) {
      inventoryItems {
        nodeId
        parentNode
        inventoryItem {
          amount
          conversionID
          impact {
            amount
            unit
            status
          }
          product{
            ...minimalProductFragment
            referenceProduct {
              id
              type
            }
            customImpacts {
              amount
            }
          }
          phase{
            id
            name
          }
        }
      }
      totalImpact {
        amount
        unit
        status
      }
    }
  }
`)

export const LEAF_INVENTORY_ITEMS = gql(`
  query leafInventoryItems($productID: ID!){
    inventory(productID: $productID) {
      product {
        ...minimalProductFragment
        hasInventory
        referenceProduct {
          id
          type
        }
        customImpacts {
          amount
        }
      }
      leafInventoryItems {
        amount
        conversionID
        impact {
          amount
          unit
          status
        }
        product {
          ...minimalProductFragment
        }
        phase{
          id
          name
        }
      }
      totalImpact {
        amount
        unit
        status
      }
    }
  }
`)

export const POSSIBLE_INVENTORY_ITEMS = gql(`
  query possibleInventoryItems($productID: ID!) {
    possibleInventoryItems(productID: $productID) {
      id
      name
      type
      unit
      labels {
        id
      }
      referenceProduct {
        ...referenceProduct
      }
    }
  }
`)
