import { EpdModule, GhgModule } from '../../../__generated__/graphql'
import { LocalStorage } from '../enum/storage'

const getStorageItem = <T>(key: string): T | undefined => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) as T : undefined;
};

const setStorageItem = <T>(key: string, data: T): void => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getModuleStorageItems = () => getStorageItem<EpdModule[]>(LocalStorage.Modules);
export const setModuleStorageItem = (modules: EpdModule[]) => setStorageItem(LocalStorage.Modules, modules);

export const getGhgModuleStorageItems = () => getStorageItem<GhgModule[]>(LocalStorage.GhgModules);
export const setGhgModuleStorageItem = (modules: GhgModule[]) => setStorageItem(LocalStorage.GhgModules, modules);
