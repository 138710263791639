import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import Intercom from '@intercom/messenger-js-sdk'

import DashboardContext from '../context/dashboard.context'
import { TopBannerContainer } from '../container/top-banner.container'
import { IAuth0User } from '../../model'
import { userAuth0Var, userVar } from '../../../graphql/cache'
import { ExternalSources } from '../../shared/enum'
import { PipVideoPlayerComponent } from '../component/pip-video-player'
import { DatasetLimitComponent } from '../component/dataset-limit.component'
import { NotificationComponent } from '../component/notification.component'

interface NavigationProps {
  readonly collapsedNavigation?: boolean
  readonly activeTopBanners?: string[]
  readonly openSidebar?: boolean
}

const Content = styled.div<NavigationProps>`
  float:left;
  width: ${(props: NavigationProps) => (props?.collapsedNavigation ? 'calc(100% - 4.5rem)' : 'calc(100% - 15rem)')};
  .with-banner-gap {
    height: ${(props: NavigationProps) => (props?.activeTopBanners && props.activeTopBanners.length > 0
    ? `calc(100% + ${props.activeTopBanners.length * 60}px) !important`
    : 'inherit')
};
  }

  .side-bar {
    float: left;
  }
`

const OutletWrapper = styled.div`
  height: calc(100vh - 100px);
`

const GlobalStyle = createGlobalStyle`
  .notification-toast-message {
      z-index: 8000;
  }
  .png-export-wrapper {
    font-variant-numeric: normal !important;
  }
`

export const ContentLayout = () => {
  const { collapsedNavigation, activeTopBanners = [] } = useContext(DashboardContext)
  const userAuth0: IAuth0User | null = userAuth0Var()
  const { intercomUserHash } = userVar() || {}

  const {
    userId, email, createdDate = '', metadata
  } = userAuth0 || {}
  const {
    firstName,
    lastName,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_term,
    utm_content,
    sign_up_type
  } = metadata || {}
  const currentDate = new Date()
  const timestamp = Math.floor(currentDate.getTime() / 1000)
  const signupDate = new Date(createdDate)
  const signupDateTimestamp = Math.floor(signupDate.getTime() / 1000)

  useEffect(() => {
    intercomUserHash && firstName && Intercom({
      api_base: ExternalSources.IntercomApiBaseUrl,
      app_id: window.env.INTERCOM_APP_ID,
      user_id: userId,
      name: firstName || lastName ? `${firstName} ${lastName}`.trim() : undefined,
      email,
      created_at: timestamp,
      user_hash: intercomUserHash,
      utm_campaign,
      utm_source,
      utm_medium,
      utm_term,
      utm_content,
      mobius_signup_date: signupDateTimestamp,
      mobius_sign_up_type: sign_up_type
    })
  }, [ intercomUserHash, firstName ])

  return (
    <>
      <GlobalStyle />
      <Content collapsedNavigation={collapsedNavigation} activeTopBanners={activeTopBanners} data-testid="content-layout" className="col h-full p-0">
        <TopBannerContainer />

        <PipVideoPlayerComponent />

        <DatasetLimitComponent />

        <NotificationComponent />

        <OutletWrapper className="p-0">
          <Outlet />
        </OutletWrapper>
      </Content>

    </>
  )
}
