import React, { useContext, useRef } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Toast } from 'primereact/toast'

import { selectedAccountVar, selectedWorkspaceVar } from '../../../graphql/cache'
import { getSegmentTrack } from '../util/segment'
import { TrackEventType } from '../enum/track-events'
import { CreateProductLifeCycleComponent } from '../component/general/create-product-lifecycle.component'
import { IDashboardContext } from '../interface/workspace-context-type'
import DashboardContext from '../../dashboard/context/dashboard.context'
import { IProduct } from '../../model'

type CreateProductLifeCycleContainerProps = {
  product?: IProduct | null
}

export const CreateProductLifeCycleContainer = ({
  product
}:CreateProductLifeCycleContainerProps) => {
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  const { account = null } = useReactiveVar(selectedAccountVar) || {}

  const navigate = useNavigate()

  const toast = useRef<Toast>(null)

  const {
    updateDashboard,
    showCreateLifeCycleFromProduct = false,
    addToBackToList = () => {}
  } = useContext<IDashboardContext>(DashboardContext)

  const [ trackEventInSegment ] = getSegmentTrack()

  const closeCreateDialog = () => {
    updateDashboard({ showCreateLifeCycleFromProduct: false })
  }

  const handleCreateLifecycle = () => {
    updateDashboard({
      showCreateLifeCycleFromProduct: false, createLifeCycleFromProduct: true, showCreateLifeCycleDialog: true, selectedEntity: { id: product?.id, name: product?.name }
    })
  }

  const chooseLifeCycle = (lifecycleId: string) => {
    trackEventInSegment(TrackEventType.OPENED_PRODUCT_LIFECYCLE)
    updateDashboard({ showCreateLifeCycleFromProduct: false })
    product?.name && addToBackToList(product?.name)
    account?.id && space?.slug && lifecycleId
      && navigate(`/account/${account?.id}/workspace/${space?.slug}/lifecycle/${lifecycleId}/detail/`)
  }

  return (
    <>
      { product && (
        <CreateProductLifeCycleComponent
          closeCreateDialog={closeCreateDialog}
          handleChooseLifeCycle={chooseLifeCycle}
          handleCreateLifeCycle={handleCreateLifecycle}
          showCreateLifeCycleFromProduct={showCreateLifeCycleFromProduct}
        />
      )}

      <Toast data-testid="create-scenario-status" ref={toast} position="top-right" />
    </>
  )
}
