import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { IDashboardContext } from '../../shared/interface/workspace-context-type';
import DashboardContext from '../../dashboard/context/dashboard.context';
import { IProduct } from '../../model';
import { DetailPanelHeaderComponent } from '../../shared/component/general/detail-panel-header.component';
import { ItemDetailContainer } from '../container/detail-panel/item-detail.container';
import { ImpactPreviewComponent } from '../component/detail-panel/view-template/impact-preview.component';

export const ProductDetailPanelLayout = () => {
  const { t } = useTranslation([ 'product', 'common', 'impact-dataset' ]);
  const { selectedEntity, updateDashboard, onSidebarHide = () => {} } = useContext<IDashboardContext>(DashboardContext);
  const { name } = selectedEntity as IProduct || {}
  const selectedProduct = selectedEntity as IProduct

  const afterCloseDetailPanel = () => {
    updateDashboard({ selectedEntity: null })
    onSidebarHide({}, true)
  }

  return (
    <div className="flex flex-column w-full h-full">
      <div className="flex w-full">
        {
          name && (
            <DetailPanelHeaderComponent
              afterCloseDetailPanel={afterCloseDetailPanel}
            />
          )
        }
      </div>

      <div className="flex flex-column w-full bg-primary-50 pb-4">
        <ImpactPreviewComponent />
      </div>

      <div className="flex flex-grow-1 pb-7">
        <div className="flex flex-column w-full">
          <div className="flex w-full align-items-center justify-content-start text-base font-medium p-4 pb-3">
            { t('labels.itemDetails') }
          </div>
          <div className="flex w-full px-3">
            <ItemDetailContainer selectedProduct={selectedProduct} />
          </div>
        </div>

      </div>
    </div>
  );
}
