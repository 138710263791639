
export enum ItemDetailComponent {
  Name = 'name',
  Unit = 'unit',
  Description = 'description',
  Module = 'module',
  GHGModule = 'ghgModule',
  Type = 'type',
  Tags = 'tags',
  MainProperty = 'mainProperty',
  AdditionalProperties = 'additionalProperties'
}
