import React, { useContext } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TagViewModeItemComponent } from './tag-view-mode-item.component';
import { TTag } from '../../../../model';
import { ItemDetailComponent } from '../../../enum/impact-detail';
import DashboardContext from '../../../../dashboard/context/dashboard.context';

type TTagViewModeComponentProps = {
  handleFormSubmit: Function,
  enableTagEdit: () => void,
  tags: TTag[]
}
export const TagViewModeComponent = ({
  handleFormSubmit,
  enableTagEdit,
  tags = []
}: TTagViewModeComponentProps) => {
  const { editableSidebar = true } = useContext(DashboardContext);

  return (
    <div className="flex flex-column gap-1 py-2">
      { tags?.length > 0 && (
        <div className="flex flex-wrap w-full gap-1 px-2">
          {
            tags.map((tag: TTag, index: number) => (
              <div className="flex" key={`tag-view-mode-item-${index}`}>
                <TagViewModeItemComponent tag={tag} onRemoveTag={() => handleFormSubmit({ removeTagId: tag.id }, ItemDetailComponent.Tags)} />
              </div>
            ))
          }
          {editableSidebar && (
            <div data-cy="add-tag-button-apple-pie" data-testid="add-tag" className="flex align-items-center cursor-pointer mr-3" onClick={enableTagEdit}>
              <FontAwesomeIcon icon={faPlus} className="text-sm text-primary-500" />
            </div>
          )}
        </div>
      )}
      { tags.length === 0 && editableSidebar && (
        <div data-cy="add-tag-button-apple-pie" data-testid="add-tag" className="flex align-items-center cursor-pointer px-2" onClick={enableTagEdit}>
          <FontAwesomeIcon icon={faPlus} className="text-sm text-primary-500" />
        </div>
      )}
    </div>
  )
}
