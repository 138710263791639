import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Frame } from '../../component/layout'
import { selectedWorkspaceVar } from '../../graphql/cache'
import { styledComponentTheme } from '../shared/constants/theme'
import { LifecycleDetailComponent } from './component/lifecycle-detail.component'
import { LifecycleDetailContainer } from './container/lifecycle-detail.container'
import { LifecycleDetailProvider } from './provider/lifecycle-detail.provider'
import { useIsMounted } from '../shared/hook/use-is-mounted'
// import { IDashboardContext } from '../shared/interface/workspace-context-type'
// import DashboardContext from '../dashboard/context/dashboard.context'
import { store } from '../../configureStore'
import { clearUiWhenDetailPanelCloseAction } from '../../redux/actions/clear.actions'
import { setIsDetailsPanelOpenAction } from '../../redux/actions/flags.actions'

export const LifecycleDetailEntry = () => {
  const isMounted = useIsMounted()
  const { lifecycleId = '' } = useParams()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  // const { clearBackToList = () => {} } = useContext<IDashboardContext>(DashboardContext)
  const componentIsMounted = isMounted()

  useEffect(() => {
    if (componentIsMounted) {
      // clearBackToList()
      // TODO : Added for compatibility with v0.9
      store.dispatch(clearUiWhenDetailPanelCloseAction())
      store.dispatch(setIsDetailsPanelOpenAction(false))
    }
  }, [ componentIsMounted ])

  return (
    <>
      <LifecycleDetailProvider>
        <Frame>
          { () => (
            <>
              { space && (
                <ThemeProvider theme={styledComponentTheme}>
                  <LifecycleDetailContainer lifecycleId={lifecycleId}>
                    <LifecycleDetailComponent />
                  </LifecycleDetailContainer>
                </ThemeProvider>
              )}
            </>
          ) }
        </Frame>
      </LifecycleDetailProvider>
    </>
  )
}
