import { useReactiveVar } from '@apollo/client'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { selectedWorkspaceVar } from '../../../graphql/cache'
import { InventoryViewType } from '../../product/enum/inventory-view-type'
import { LifecycleDetailDialogComponent } from '../component/dialog/lifecycle-detail-dialog.component'
import { LifecycleInsightContainer } from '../container/lifecycle-insight.container'
import { TLifecycleDetailContext } from '../interface/lifecycle-detail-context.type'
import LifecycleDetailContext from '../provider/context/lifecycle-detail.context'
import { LifecycleDetailBarLayout } from './lifecycle-detail-bar.layout'
import { LifecycleDetailDonutLayout } from './lifecycle-detail-donut.layout'
import { LifecycleDetailTableLayout } from './lifecycle-detail-table.layout'
import { LifecyclePdfDoughnutComponent } from '../component/chart/lifecycle-pdf-doughnut.component'

export const InsightLayout = () => {
  const {
    selectedInventoryViewType,
    loadingLifecycleLeafItems,
    showLifecycleDetailPanelOnly,
    refetchLifecycleLeafItems,
    showPdfInsightsResult,
    transformedLifecycleLeafItems
  } = useContext<TLifecycleDetailContext>(LifecycleDetailContext)
  const { lifecycleId } = useParams()
  const { space = null } = useReactiveVar(selectedWorkspaceVar) || {}
  useEffect(() => {
    if (space?.impactCategory?.id) {
      reloadLifecycleLeafItems()
    }
  }, [ space?.impactCategory?.id ])

  const isInsightViewType = (viewType: InventoryViewType) => !loadingLifecycleLeafItems && viewType === selectedInventoryViewType

  const reloadLifecycleLeafItems = () => {
    refetchLifecycleLeafItems && refetchLifecycleLeafItems()
  }

  return (
    <LifecycleInsightContainer lifecycleId={lifecycleId}>

      { isInsightViewType(InventoryViewType.FlatView) && <LifecycleDetailTableLayout /> }

      { isInsightViewType(InventoryViewType.BarChart) && <LifecycleDetailBarLayout /> }

      { isInsightViewType(InventoryViewType.Sunburst) && <LifecycleDetailDonutLayout />}

      { transformedLifecycleLeafItems && showPdfInsightsResult && <LifecyclePdfDoughnutComponent /> }

      {/* // TODO : From v0.9 : To be refactored */}
      { !showLifecycleDetailPanelOnly && <LifecycleDetailDialogComponent reload={reloadLifecycleLeafItems} /> }

    </LifecycleInsightContainer>
  )
}
